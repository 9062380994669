import { AppBar, Tab, Tabs, Toolbar } from '@mui/material'
import { useIsAuthenticated } from 'hooks'
import { Link, useLocation } from 'react-router-dom'

const NewNavBar = () => {
  // Reference: https://stackoverflow.com/questions/74556799/how-to-highlight-and-get-active-state-for-main-navigation-menu-item-and-sub-navi
  const { pathname } = useLocation()
  const base = `/${pathname.slice(1).split('/').shift()}`
  const isAuthenticated = useIsAuthenticated()
  const links = [
    { to: '/', label: 'Home' },
    { to: '/labels', label: 'Labels' },
    { to: '/brands', label: 'Brands' },
    { to: '/in-progress', label: 'In Progress' },
    { to: '/partial', label: 'Partial Uploads' },
    { to: '/uploads', label: 'Uploads For Review' },
    { to: '/issue-gift-cards', label: 'Issue Gift Cards' },
    { to: '/completed', label: 'Completed' },
    { to: '/logout', label: 'Logout' },
  ]

  return (
    <>
      <AppBar position="fixed" sx={{ top: 'auto' }}>
        <Tabs
          value={base}
          indicatorColor="secondary"
          textColor="inherit"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          {isAuthenticated ? (
            links.map((link) => (
              <Tab
                component={Link}
                to={link.to}
                label={link.label}
                key={link.to}
                value={link.to}
              />
            ))
          ) : (
            <Tab
              component={Link}
              to="/login"
              label="Login"
              key="/login"
              value="/login"
            />
          )}
        </Tabs>
      </AppBar>
      <Toolbar />
    </>
  )
}

export default NewNavBar
