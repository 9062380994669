import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter as Router } from 'react-router-dom'
import '@fontsource/roboto'
import {
  AppBar,
  Container,
  ThemeProvider,
  Toolbar,
  Typography,
  createTheme,
} from '@mui/material'

import './index.css'
import { blueGrey } from '@mui/material/colors'
import NewNavBar from 'components/new_nav_bar'
import ENV from 'environment'
import AppRoutes from 'routes'

const theme = createTheme({
  palette: {
    info: blueGrey,
  },
  components: {
    MuiCardContent: {
      styleOverrides: {
        root: {
          '&:last-child': {
            paddingBottom: '1rem',
          },
        },
      },
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Router>
        {!ENV.IS_PROD && (
          <>
            <AppBar position="sticky" sx={{ backgroundColor: 'black' }}>
              <Toolbar variant="dense">
                <Typography textAlign="center" sx={{ flexGrow: 1 }}>
                  {ENV.IS_STAGING
                    ? 'STAGING ENVIRONMENT'
                    : 'LOCAL DEV ENVIRONMENT'}
                </Typography>
              </Toolbar>
            </AppBar>
          </>
        )}
        {/* <NavBar></NavBar> */}
        <NewNavBar />
        <Container sx={{ paddingBottom: '2rem' }}>
          <AppRoutes />
        </Container>
      </Router>
    </ThemeProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
