import { Route, Routes, Outlet, Navigate, useLocation } from 'react-router-dom'
import '@fontsource/roboto'

import './index.css'
import Login from 'routes/login'
import Home from 'routes/home'
import Uploads from 'routes/uploads'
import Upload from 'routes/upload'
import UploadsToIssueGiftCard from 'routes/uploads_to_issue_gift_card'
import Labels from 'routes/labels'
import BrandTiers from 'routes/brand_tiers'
import { UploadStatus } from 'models'
import { useIsAuthenticated } from 'hooks'
import Logout from 'routes/logout'
import ChangePassword from 'routes/change_password'
import CompletedUploads from 'routes/completed_uploads'

const PrivateOutlet = ({
  shouldRedirectToPage = true,
}: {
  shouldRedirectToPage?: boolean
}) => {
  const location = useLocation()
  const isAuthenticated = useIsAuthenticated()
  if (isAuthenticated) {
    return <Outlet />
  }

  return (
    <Navigate
      to="/login"
      state={shouldRedirectToPage ? { from: location.pathname } : undefined}
    />
  )
}

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route element={<PrivateOutlet shouldRedirectToPage={false} />}>
        <Route path="/logout" element={<Logout />} />
      </Route>
      <Route element={<PrivateOutlet />}>
        <Route path="/" element={<Home />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/brands" element={<BrandTiers />} />
        <Route
          path="/in-progress"
          element={<Uploads uploadStatus={UploadStatus.IN_PROGRESS} />}
        />
        <Route
          path="/in-progress/:id"
          element={<Upload uploadStatus={UploadStatus.IN_PROGRESS} />}
        />
        <Route
          path="/partial"
          element={<Uploads uploadStatus={UploadStatus.PARTIAL} />}
        />
        <Route
          path="/partial/:id"
          element={<Upload uploadStatus={UploadStatus.PARTIAL} />}
        />
        <Route
          path="/uploads"
          element={<Uploads uploadStatus={UploadStatus.IN_REVIEW} />}
        />
        <Route
          path="/uploads/:id"
          element={<Upload uploadStatus={UploadStatus.IN_REVIEW} />}
        />
        <Route path="/issue-gift-cards" element={<UploadsToIssueGiftCard />} />
        <Route path="/completed" element={<CompletedUploads />} />
        <Route
          path="/completed/:id"
          element={<Upload uploadStatus={UploadStatus.COMPLETED} />}
        />
        <Route path="/labels" element={<Labels />} />
        <Route path="*" element={<>Page Not Found</>} />
      </Route>
    </Routes>
  )
}

export default AppRoutes
