import { Box, Stack, CircularProgress, Typography } from '@mui/material'
import { logout_user } from 'api/clothes_service'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Logout = () => {
  const navigate = useNavigate()
  useEffect(() => {
    const logout = async () => {
      await logout_user()
      navigate('/login')
    }
    logout()
  }, [navigate])
  return (
    <Box display="flex" justifyContent="center" pt={2}>
      <Stack display="flex" alignItems="center" spacing={1}>
        <CircularProgress />
        <Typography variant="body1">Logging out...</Typography>
      </Stack>
    </Box>
  )
}

export default Logout
