import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { edit_brand_tier, search_brand_tier } from 'api/clothes_service'
import BasicDialog from 'components/basic_dialog'
import ButtonWithLoading from 'components/button_with_loading'
import { useDocumentTitle } from 'hooks'
import { BrandTier, Tier, TierValues } from 'models'
import { useState } from 'react'

interface EditTierFormProps {
  brandTier: BrandTier
  onSubmit: () => void
}

const EditTierForm = ({ brandTier, onSubmit }: EditTierFormProps) => {
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [tier, setTier] = useState<Tier>(brandTier.tier)

  return (
    <Box
      component="form"
      onSubmit={async (event) => {
        setIsSaving(true)
        event.preventDefault()
        await edit_brand_tier({
          brand: brandTier.brand,
          tier: tier,
        })
        setIsSaving(false)
        onSubmit()
      }}
    >
      <Stack spacing={3}>
        <Typography>
          Note: Changing the brand tier will NOT affect the pricing of existing
          items, unless you manually edit them.
        </Typography>
        <Typography>{brandTier.brand}</Typography>
        <TextField
          required
          select
          value={tier}
          label="Tier"
          fullWidth
          onChange={(event) => setTier(Number(event.target.value) as Tier)}
        >
          {TierValues.map((tier) => (
            <MenuItem key={tier} value={tier}>
              {tier}
            </MenuItem>
          ))}
        </TextField>
        <Stack direction="row" justifyContent="right">
          <ButtonWithLoading
            type="submit"
            variant="contained"
            isLoading={isSaving}
          >
            Save
          </ButtonWithLoading>
        </Stack>
      </Stack>
    </Box>
  )
}

const BrandTiers = () => {
  useDocumentTitle('Brand Tiers')

  const [searchTerm, setSearchTerm] = useState<string>('')
  const [isSearching, setIsSearching] = useState<boolean>(false)
  const [brandTiers, setBrandTiers] = useState<Array<BrandTier>>([])
  const [selectedIndex, setSelectedIndex] = useState<number>(0)
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false)

  const handleEditItem = (i: number) => {
    setSelectedIndex(i)
    setIsEditDialogOpen(true)
  }

  const retrieveBrandTiers = async () => {
    setIsSearching(true)
    const brandTiers = await search_brand_tier(searchTerm)
    setBrandTiers(brandTiers)
    setIsSearching(false)
  }

  return (
    <Stack spacing={3}>
      <Typography variant="h3">View and Edit Brand Tiers</Typography>
      <Box
        component="form"
        onSubmit={async (event) => {
          event.preventDefault()
          await retrieveBrandTiers()
        }}
      >
        <Stack direction="row" spacing={1}>
          <TextField
            required
            fullWidth
            label="Brand"
            value={searchTerm}
            onChange={(event) => setSearchTerm(event.target.value)}
            size="small"
          />
          <ButtonWithLoading
            variant="contained"
            type="submit"
            isLoading={isSearching}
          >
            Search
          </ButtonWithLoading>
        </Stack>
      </Box>
      <Stack spacing={2}>
        {brandTiers.length === 0 ? (
          <Typography>No results found.</Typography>
        ) : (
          brandTiers.map((brandTier, i) => (
            <Card elevation={3} key={brandTier.brand}>
              <div>
                <CardContent>
                  <Grid container>
                    <Grid item xs>
                      <Typography>{brandTier.brand}</Typography>
                      <Typography>Tier: {brandTier.tier}</Typography>
                    </Grid>
                    <Grid item xs="auto" display="flex" alignItems="end">
                      <Button
                        variant="outlined"
                        onClick={() => handleEditItem(i)}
                      >
                        Edit
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </div>
            </Card>
          ))
        )}
      </Stack>
      <BasicDialog
        open={isEditDialogOpen}
        onClose={() => setIsEditDialogOpen(false)}
        title="Edit brand tier"
      >
        <EditTierForm
          brandTier={brandTiers[selectedIndex]}
          onSubmit={() => {
            setIsEditDialogOpen(false)
            retrieveBrandTiers()
          }}
        />
      </BasicDialog>
    </Stack>
  )
}

export default BrandTiers
