import { useLayoutEffect } from 'react'
import Cookies from 'js-cookie'
import ENV from 'environment'

const baseTitle = 'Thryft Clothes Crediting'

export const useDocumentTitle = (title?: string) => {
  useLayoutEffect(() => {
    document.title = title !== undefined ? `${title} - ${baseTitle}` : baseTitle
    if (!ENV.IS_PROD) document.title = `[${ENV.name}] ${document.title}`
  }, [title])
}

/**
 * Trivial check for whether user is authenticated. Does not account for the possibility of user's token being expired.
 *
 * @returns Whether user is authenticated
 */
export const useIsAuthenticated = () => {
  return Cookies.get('csrf_access_token') !== undefined
}
