import { Button, List, ListItem, Grid, Typography } from '@mui/material'
import { useDocumentTitle } from 'hooks'
import { Link } from 'react-router-dom'

const Home = () => {
  useDocumentTitle()

  return (
    <>
      <Typography variant="h3">Thryft Clothes Crediting</Typography>
      <Grid container mt={1} spacing={1}>
        <Grid container item xs textAlign="center">
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/change-password"
            sx={{ height: '100%' }}
          >
            Change Password
          </Button>
        </Grid>
        <Grid container item xs="auto">
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/logout"
            sx={{ height: '100%' }}
          >
            Logout
          </Button>
        </Grid>
      </Grid>
      <Typography variant="h4" mt={3}>
        Release Notes
      </Typography>
      <Typography variant="h6" mt={2}>
        Sun 7 Jan 2024
      </Typography>
      <List sx={{ listStyleType: 'disc', pl: 4 }} dense>
        <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
          Updated pricing formula.
        </ListItem>
      </List>
      <Typography variant="h6" mt={2}>
        Fri 5 Jan 2024
      </Typography>
      <List sx={{ listStyleType: 'disc', pl: 4 }} dense>
        <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
          Added brand tier "4" (luxury).
        </ListItem>
        <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
          Updated pricing and crediting formula.
        </ListItem>
        <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
          Updated labels page with new prices.
        </ListItem>
      </List>
      <Typography variant="h6" mt={2}>
        Fri 13 Oct 2023
      </Typography>
      <List sx={{ listStyleType: 'disc', pl: 4 }} dense>
        <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
          Infinite scrolling and sorting by approval date, uploaded date, and
          collection date (newest first) added for completed uploads.
        </ListItem>
      </List>
      <Typography variant="h6" mt={2}>
        Mon 2 Oct 2023
      </Typography>
      <List sx={{ listStyleType: 'disc', pl: 4 }} dense>
        <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
          Login system added.
        </ListItem>
      </List>
      <Typography variant="h6" mt={2}>
        Sat 30 Sep 2023
      </Typography>
      <List sx={{ listStyleType: 'disc', pl: 4 }} dense>
        <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
          Fixed rounding errors with total crediting amount.
        </ListItem>
      </List>
      <Typography variant="h6" mt={2}>
        Thu 28 Sep 2023
      </Typography>
      <List sx={{ listStyleType: 'disc', pl: 4 }} dense>
        <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
          Crediting formula updated.
        </ListItem>
        <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
          Allow special characters (e.g. brackets) in brand name.
        </ListItem>
      </List>
      <Typography variant="h6" mt={2}>
        Tue 19 Sep 2023
      </Typography>
      <List sx={{ listStyleType: 'disc', pl: 4 }} dense>
        <ListItem sx={{ display: 'list-item', pl: 0, pt: 0, pb: 0 }}>
          Emails for donations are now supported.
        </ListItem>
      </List>
    </>
  )
}

export default Home
