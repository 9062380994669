import { Box, Container, TextField, Typography } from '@mui/material'
import { login_user } from 'api/clothes_service'
import ButtonWithLoading from 'components/button_with_loading'
import { useDocumentTitle } from 'hooks'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const Login = () => {
  useDocumentTitle('Login')

  const navigate = useNavigate()
  const location = useLocation()

  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true)
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    await login_user(
      data.get('email') as string,
      data.get('password') as string
    )
    navigate(location.state?.from ?? '/')
    setIsLoading(false)
  }

  return (
    <Container maxWidth="xs">
      <Typography variant="h4">Thryft Clothes Crediting</Typography>
      <Box component="form" onSubmit={handleSubmit}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
        />
        <ButtonWithLoading
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          isLoading={isLoading}
        >
          Login
        </ButtonWithLoading>
      </Box>
    </Container>
  )
}

export default Login
