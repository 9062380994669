import { useCallback, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {
  Stack,
  Typography,
  CircularProgress,
  Box,
  Button,
  Alert,
  Snackbar,
} from '@mui/material'

import {
  approve_crediting_upload,
  delete_clothing_item,
  delete_clothes_crediting_upload,
  get_clothes_crediting_upload,
  mark_crediting_upload_as_complete,
  replace_clothing_item,
} from 'api/clothes_service'
import { ClothingDataDB, UploadStatus } from 'models'
import UploadCard from 'components/upload_card'
import BasicDialog from 'components/basic_dialog'
import ButtonWithAutoLoading from 'components/button_with_auto_loading'
import ClothingItemForm from 'components/clothing_item_form'
import { useDocumentTitle } from 'hooks'
import ClothingItemAccordion from 'components/clothing_item_accordion'
import { NotFoundError } from 'errors'
import ClothingRejectedItemAccordion from 'components/clothing_rejected_items_accordion'

interface UploadProps {
  uploadStatus: UploadStatus
}

const UploadContent = ({ uploadStatus }: UploadProps) => {
  useDocumentTitle('Review Upload')

  const [clothingData, setClothingData] = useState<ClothingDataDB>()
  const [isMarkCompleteDialogOpen, setIsMarkCompleteDialogOpen] =
    useState<boolean>(false)
  const [isApproveDialogOpen, setIsApproveDialogOpen] = useState<boolean>(false)
  const [isDeleteUploadDialogOpen, setIsDeleteUploadDialogOpen] =
    useState<boolean>(false)
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false)
  const [isDeleteItemDialogOpen, setIsDeleteItemDialogOpen] =
    useState<boolean>(false)
  const [selectedIndex, setSelectedIndex] = useState<number>(0)
  const [error, setError] = useState<string>()

  const [showSnackbarError, setShowSnackbarError] = useState<boolean>(false)
  const [snackbarError, setSnackbarError] = useState<string>('')
  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setShowSnackbarError(false)
  }

  const { id } = useParams()
  const navigate = useNavigate()

  const populateClothingUpload = useCallback(async () => {
    setError(undefined)
    try {
      const clothingData = await get_clothes_crediting_upload(id!)
      setClothingData(clothingData)
    } catch (e: any) {
      if (e instanceof NotFoundError) {
        setError('Upload not found.')
        return
      }
      setError(e.message)
    }
  }, [id])

  useEffect(() => {
    populateClothingUpload()
  }, [isEditDialogOpen, isDeleteItemDialogOpen, populateClothingUpload])

  if (id === undefined) return <></>

  const handleEditItem = (i: number) => {
    setSelectedIndex(i)
    setIsEditDialogOpen(true)
  }

  const handleDeleteItem = (i: number) => {
    setSelectedIndex(i)
    setIsDeleteItemDialogOpen(true)
  }

  if (error !== undefined) {
    return (
      <Alert severity="error">
        <Typography variant="h5">{error}</Typography>
      </Alert>
    )
  }

  if (clothingData === undefined) {
    return (
      <Box display="flex" justifyContent="center" pt={2}>
        <Stack display="flex" alignItems="center" spacing={1}>
          <CircularProgress />
          <Typography variant="body1">Loading data...</Typography>
        </Stack>
      </Box>
    )
  }

  if (clothingData.status !== uploadStatus) {
    return <Typography>Upload is of a different status.</Typography>
  }

  return (
    <>
      <UploadCard clothingData={clothingData} />
      <Stack spacing={2}>
        <Typography variant="h5">Clothing Items</Typography>
        <Stack>
          {clothingData.clothes.length === 0 && <Typography>None.</Typography>}
          {clothingData.clothes.map((clothingItem, i) => (
            <ClothingItemAccordion
              key={clothingItem.sku}
              clothingItem={clothingItem}
              index={i}
              buttons={
                clothingData.status === UploadStatus.IN_REVIEW && (
                  <>
                    <Button
                      variant="outlined"
                      onClick={() => handleEditItem(i)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => handleDeleteItem(i)}
                    >
                      Delete
                    </Button>
                  </>
                )
              }
            />
          ))}
        </Stack>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h5">Rejected Items</Typography>
        <Stack>
          {clothingData.rejected_items.length === 0 && (
            <Typography>None.</Typography>
          )}
          {clothingData.rejected_items.map((rejectedItem, i) => (
            <ClothingRejectedItemAccordion
              key={i}
              rejectedItem={rejectedItem}
              index={i}
            />
          ))}
        </Stack>
      </Stack>
      <Stack spacing={1} direction="row" justifyContent="right">
        {clothingData.status === UploadStatus.IN_REVIEW && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsApproveDialogOpen(true)}
          >
            Approve
          </Button>
        )}
        {clothingData.status === UploadStatus.PARTIAL && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsMarkCompleteDialogOpen(true)}
          >
            Mark as Complete
          </Button>
        )}
        {(clothingData.status === UploadStatus.IN_REVIEW ||
          clothingData.status === UploadStatus.PARTIAL) && (
          <Button
            variant="contained"
            color="error"
            onClick={() => setIsDeleteUploadDialogOpen(true)}
          >
            Delete
          </Button>
        )}
      </Stack>

      <BasicDialog
        open={isApproveDialogOpen}
        onClose={() => setIsApproveDialogOpen(false)}
        title="Approve this upload?"
        actions={
          <ButtonWithAutoLoading
            variant="contained"
            onClick={async () => {
              try {
                await approve_crediting_upload(id)
              } catch (e: any) {
                setSnackbarError(e.message)
                setShowSnackbarError(true)
                return
              }

              setIsApproveDialogOpen(false)
              navigate('/uploads')
            }}
          >
            Yes
          </ButtonWithAutoLoading>
        }
      >
        <Typography variant="body1">
          Upon approving, an email will be sent to the customer and the clothing
          items will be queued for upload to Shopify (POS channel). It may take
          up to 24 hours for the clothes to show up on Shopify.
        </Typography>
      </BasicDialog>

      <BasicDialog
        open={isMarkCompleteDialogOpen}
        onClose={() => setIsMarkCompleteDialogOpen(false)}
        title="Mark this upload as complete?"
        actions={
          <ButtonWithAutoLoading
            variant="contained"
            onClick={async () => {
              try {
                await mark_crediting_upload_as_complete(id)
              } catch (e: any) {
                setSnackbarError(e.message)
                setShowSnackbarError(true)
                return
              }

              setIsMarkCompleteDialogOpen(false)
              navigate('/partial')
            }}
          >
            Yes
          </ButtonWithAutoLoading>
        }
      >
        <Typography variant="body1">
          The upload will still undergo review and require approval.
        </Typography>
      </BasicDialog>

      <BasicDialog
        open={isDeleteUploadDialogOpen}
        onClose={() => setIsDeleteUploadDialogOpen(false)}
        title="Delete this upload?"
        actions={
          <ButtonWithAutoLoading
            variant="contained"
            color="error"
            onClick={async () => {
              try {
                await delete_clothes_crediting_upload(id)
              } catch (e: any) {
                setSnackbarError(e.message)
                setShowSnackbarError(true)
                return
              }
              setIsDeleteUploadDialogOpen(false)
              navigate('/uploads')
            }}
          >
            Yes, Delete
          </ButtonWithAutoLoading>
        }
      >
        <Typography variant="body1">
          All clothing items in this upload and all information associated with
          this upload will be deleted. This action is irreversible.
        </Typography>
      </BasicDialog>

      <BasicDialog
        open={isEditDialogOpen}
        onClose={() => setIsEditDialogOpen(false)}
        title="Edit upload"
      >
        <ClothingItemForm
          clothingItem={clothingData.clothes[selectedIndex]}
          onSubmit={async (clothingItem) => {
            try {
              await replace_clothing_item(
                clothingData.id,
                clothingData.clothes[selectedIndex].sku,
                clothingItem
              )
            } catch (e: any) {
              setSnackbarError(e.message)
              setShowSnackbarError(true)
              return
            }
            setIsEditDialogOpen(false)
          }}
        ></ClothingItemForm>
      </BasicDialog>

      <BasicDialog
        open={isDeleteItemDialogOpen}
        onClose={() => setIsDeleteItemDialogOpen(false)}
        title="Delete this item?"
        actions={
          <ButtonWithAutoLoading
            variant="contained"
            color="error"
            onClick={async () => {
              try {
                await delete_clothing_item(
                  clothingData.id,
                  clothingData.clothes[selectedIndex].sku
                )
              } catch (e: any) {
                setSnackbarError(e.message)
                setShowSnackbarError(true)
                return
              }
              setIsDeleteItemDialogOpen(false)
            }}
          >
            Yes, Delete
          </ButtonWithAutoLoading>
        }
      >
        <Typography variant="body1">This action is irreversible.</Typography>
      </BasicDialog>

      <Snackbar
        open={showSnackbarError}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleSnackbarClose}
      >
        <Alert
          elevation={3}
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: '100%' }}
        >
          <Typography variant="h5">{snackbarError}</Typography>
        </Alert>
      </Snackbar>
    </>
  )
}

const Upload = ({ uploadStatus }: UploadProps) => {
  const getHeader = (uploadStatus: UploadStatus) => {
    switch (uploadStatus) {
      case UploadStatus.IN_PROGRESS:
        return 'In-Progress Upload'
      case UploadStatus.PARTIAL:
        return 'Partial Upload'
      case UploadStatus.IN_REVIEW:
        return 'Review Upload'
      case UploadStatus.GIFT_CARD:
        return 'Approved Upload (Gift Card Not Yet Issued)'
      case UploadStatus.COMPLETED:
        return 'Completed Upload'
    }
  }

  return (
    <Stack spacing={4}>
      <Typography variant="h3">{getHeader(uploadStatus)}</Typography>
      <UploadContent uploadStatus={uploadStatus} />
    </Stack>
  )
}

export default Upload
