import { useCallback, useEffect, useState } from 'react'
import {
  Alert,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material'

import { ClothingDataDB } from 'models'
import {
  get_crediting_uploads_to_issue_gift_card,
  mark_crediting_upload_gift_card_issued,
} from 'api/clothes_service'
import { useDocumentTitle } from 'hooks'
import ButtonWithAutoLoading from 'components/button_with_auto_loading'

const UploadsToIssueGiftCardContent = () => {
  useDocumentTitle('Issue Shopify Gift Cards')

  const [clothingUploads, setClothingUploads] =
    useState<Array<ClothingDataDB>>()

  const [showSnackbarError, setShowSnackbarError] = useState<boolean>(false)
  const [snackbarError, setSnackbarError] = useState<string>('')
  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setShowSnackbarError(false)
  }

  const populateClothingUploads = useCallback(async () => {
    const clothingUploads = await get_crediting_uploads_to_issue_gift_card()
    setClothingUploads(clothingUploads)
  }, [])

  useEffect(() => {
    populateClothingUploads()
  }, [populateClothingUploads])

  if (clothingUploads === undefined) {
    return (
      <Box display="flex" justifyContent="center" pt={2}>
        <Stack display="flex" alignItems="center" spacing={1}>
          <CircularProgress />
          <Typography variant="body1">Loading data...</Typography>
        </Stack>
      </Box>
    )
  }

  if (clothingUploads.length === 0) {
    return <Typography>No uploads.</Typography>
  }

  return (
    <>
      {clothingUploads.map((clothingData) => (
        <Card
          key={clothingData.id}
          elevation={3}
          sx={{ backgroundColor: '#e1f5fe' }}
        >
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs>
                <Typography variant="body1">
                  Customer name: {clothingData.customer_first_name}
                </Typography>
                <Typography variant="body1">
                  Email: {clothingData.customer_email}
                </Typography>
                <Typography variant="body1">
                  No. of accepted items: {clothingData.clothes.length}
                </Typography>
                <Typography variant="body1">
                  Collection date:{' '}
                  {clothingData.collection_date.toLocaleDateString(undefined, {
                    dateStyle: 'medium',
                  })}
                </Typography>
              </Grid>
              <Grid item xs="auto">
                <Divider orientation="vertical" />
              </Grid>
              <Grid container item xs="auto" direction="column">
                <Grid item xs>
                  <Typography variant="h4">
                    ${clothingData.total_crediting_amount}
                  </Typography>
                  <Typography variant="body1">
                    Email: {clothingData.customer_email}
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  <ButtonWithAutoLoading
                    variant="contained"
                    fullWidth
                    onClick={async () => {
                      try {
                        await mark_crediting_upload_gift_card_issued(
                          clothingData.id
                        )
                      } catch (e: any) {
                        setSnackbarError(e.message)
                        setShowSnackbarError(true)
                        return
                      }
                      await populateClothingUploads()
                    }}
                  >
                    Mark as Issued
                  </ButtonWithAutoLoading>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ))}
      <Snackbar
        open={showSnackbarError}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleSnackbarClose}
      >
        <Alert
          elevation={3}
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: '100%' }}
        >
          <Typography variant="h5">{snackbarError}</Typography>
        </Alert>
      </Snackbar>
    </>
  )
}

const UploadsToIssueGiftCard = () => {
  return (
    <Stack spacing={2}>
      <Typography variant="h3">Issue Shopify Gift Cards</Typography>
      <UploadsToIssueGiftCardContent />
    </Stack>
  )
}

export default UploadsToIssueGiftCard
